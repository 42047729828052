import React, { useEffect, useState } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link, Container, Theme, Button, Backdrop, CircularProgress, Tabs, Tab, Box } from '@material-ui/core';

import { Seo, Lazy, EditForm, SearchForm, FormWrapper } from '../components';
import { FC } from '../util';
import { Circuit } from '../types/circuit';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(2)
    },
    link: {
        color: theme.palette.text.secondary,
        textDecoration: 'underline'
    }
}));

interface LinkTabProps {
  label?: string;
  href?: string;
}

function LinkTab(props: LinkTabProps) {
  return (
    <Tab
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const EditQuote: FC<{quoteUuidFromParams: string}> = ({quoteUuidFromParams}) => {
    const styles = useStyles();
    const [circuits, setCircuits] = useState<Circuit[]>([]);

    const [isLoading, setIsLoading] = useState(false);

    useEffect (() => {
      async function getCircuits() {
        try {
          const res = await fetch(`https://nestjs.moris.co.uk/lookup/list_circuits`)
          if (!res.ok) {
            throw new Error(`Response failed`)
          }
          setCircuits(await res.json())
          
        } catch (error) {
          console.error(error);
        }
      }
      getCircuits();
    },[]);


    return (
            <>
              <Seo title="Edit Quote" />
              <SearchForm isLoading={isLoading} setIsLoading={setIsLoading} quoteUuidFromParams={quoteUuidFromParams}/>
              <EditForm circuitData={circuits} isLoading={isLoading} setIsLoading={setIsLoading}/>
            </>
    );
};

export default EditQuote;