import React, { useContext, useEffect, useLayoutEffect } from 'react';
import { graphql, Link as GatsbyLink, PageProps } from 'gatsby';
import { Theme, Link, Button, Grid, Typography, Container, Tabs, Tab, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';


import { Seo } from '../components';
import { FC } from '../util';
import { QuoteContext } from '../state/quoteState';
import EditQuote from '../tabScenes/edit-quote';
import SubmitQuote from '../tabScenes/submit-quote';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2)
  }
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const IndexPage: FC<PageProps<Queries.IndexPageQuery>> = ({data, location}) => {
    const styles = useStyles();

    const [value, setValue] = React.useState(0);

    const [quoteUuidFromParams, setQuoteUuidFromParams] = React.useState("");

    console.log(location);

    const handleChange = (event: any, newValue: number) => {
      setValue(newValue);
    };

    useLayoutEffect(() => {
      const params = new URLSearchParams(location.search);
      const editParam = params.get("editpage");
      if (editParam) {
        setValue(1);
        if (params.get("quoteID") != null) setQuoteUuidFromParams(params.get("quoteID")!);
      }
    },[])
 
    return (
      
      <Container className={styles.root}>
        <Seo title="Home" />
        <Tabs 
          value={value} 
          onChange={handleChange} 
          centered
          indicatorColor='primary'
        >
          <Tab label="Submit Page" {...a11yProps(0)}/>
          <Tab label="Edit Page" {...a11yProps(1)}/>
        </Tabs>
        <TabPanel value={value} index={0}>
          <SubmitQuote template={data.file}/>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <EditQuote quoteUuidFromParams={quoteUuidFromParams}/>
        </TabPanel>
        </Container>
      
    );
};

export const query = graphql`
  query IndexPage {
    file(name: {eq: "MorisVehicleTemplate"}, extension: {eq: "xlsx"}) {
      name
      publicURL
    }
  }`

export default IndexPage;
