import React, { useEffect, useState } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { makeStyles } from '@material-ui/styles';
import { Typography, Link, Container, Theme, Button } from '@material-ui/core';

import { Seo, Lazy, SubmitForm } from '../components';
import { FC } from '../util';
import { Circuit } from '../types/circuit';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(2)
    }
}));

const SubmitQuote: FC<{template: Queries.IndexPageQuery["file"]}> = ({template}) => {
    const styles = useStyles();
    const [circuits, setCircuits] = useState<Circuit[]>([]);
    
    useEffect (() => {
      async function getCircuits() {
        try {
          const res = await fetch(`https://nestjs.moris.co.uk/lookup/list_circuits`)
          if (!res.ok) {
            throw new Error(`Response failed`)
          }
          setCircuits(await res.json())
          
        } catch (error) {
          console.error(error);
        }
      }
      getCircuits();
    },[]);

    return (
              <>
                {/* <Link component={GatsbyLink} to="/">
                    <Button
                        variant='contained'
                        color='primary'
                    >
                        Go back to the homepage
                    </Button>
                </Link> */}
                <Seo title="Submit Quote" />
                <SubmitForm circuits={circuits} template={template}/>
              </>
                    
    );
};

export default SubmitQuote;